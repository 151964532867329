<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">報告書 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                v-model="search.year"
                :options="yearList">
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成プログラム</div>
            <div>
              <b-form-select
                v-model="search.series"
                :options="seriesList">
                <template v-slot:first>
                  <option :value="null">-- 選択してください。 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>学生名</div>
            <div>
              <b-form-input
                v-model="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>受付状況</div>
            <div>
              <b-form-select
                v-model="search.reptypeStatus"
                :options="reptypeStatusOption">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock">
            <div>ステータス</div>
            <div>
              <b-form-select
                v-model="search.status"
                :options="statusList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="uniReportListTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="appList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template v-slot:cell(series)="row">
            {{seriesNameList[row.item.series]}}
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="`/university/reports/edit/${row.item.id}`">
              詳細
            </b-link>
          </template>
        </b-table>

        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'UniversityApplicationsList',
  data() {
    return {
      search: {
        year: null,
        name: '',
        reptypeStatus: '',
        series: null,
        status: '',
      },
      rawSeriesList: [],
      statusList: CONST_STATUS.UNI_REP_STATUS_OPTION,
      dbAppList: [],
      header: [
        { key: 'kana', label: '学生名', sortable: true },
        { key: 'apptype', label: '助成プログラム', sortable: true },
        { key: 'term', label: '提出期間', sortable: true },
        { key: 'reptypeStatus', label: '受付状況', sortable: true },
        { key: 'statusText', label: 'ステータス', sortable: true },
        { key: 'btn', label: '成果報告書', sortable: false },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      yearList: [],
      filterdIdList: [],
      reptypeStatusOption: ['報告期間外', '受付中'],
    };
  },
  methods: {
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const seriesSort = search.series === null || lineData.seriesId === Number(search.series);
      const reptypeSort = search.reptypeStatus === '' || lineData.reptypeStatus === search.reptypeStatus;
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      return nameSort && seriesSort && reptypeSort && statusSort;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.filterdIdList = filteredItems.map((data) => {
        return data.id;
      });
    },
    async fetchList() {
      const param = { year: this.search.year };
      const response = await api.send('/api/uni/report/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.dbAppList = response.data.list;
      this.yearList = response.data.yearList;
      if (this.search.year === null) {
        this.setFilter('year', this.yearList[0] || null);
      }
      this.rawSeriesList = response.data.seriesList;
    },
    setFilter(key, value) {
      this.$set(this.search, key, value);
    },
  },
  computed: {
    seriesList() {
      const result = {};
      this.rawSeriesList.forEach((series) => {
        result[series.id] = series.name;
      });
      return result;
    },
    appList() {
      const appList = this.dbAppList.map((application) => {
        return {
          id: application.appId,
          apptype: application.name,
          name: application.user_name,
          kana: application.user_name_kane,
          year: application.year,
          status: application.status,
          reptypeStatus: application.reptype_status,
          statusText: application.status_text,
          seriesId: application.series_id,
          term: `${application.from} ~ ${application.to}`,
        };
      });
      return appList;
    },
    totalRow() {
      return this.appList.length;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  watch: {
    'search.year': async function () {
      this.$store.dispatch('page/onWaiting');
      await this.fetchList();
      this.$store.dispatch('page/offWaiting');
    },
  },
  // ロード画面
  async created() {
    await this.fetchList();
  },
};
</script>

<style>
  .uniReportListTable thead th:nth-of-type(4) {
    width: 120px !important;
  }
  .uniReportListTable thead th:nth-of-type(5) {
    width: 120px !important;
  }
  .uniReportListTable thead th:nth-of-type(6) {
    width: 120px !important;
  }
  .CmsContentsFiledsList .uniReportListTable .tableHead {
    background: #120f69 !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
